import React, { Component } from 'react';

import RichTextWrapper from '../RichTextWrapper';

import RichTextRenderer from '../../../utils/content/RichTextRenderer';

import * as Breakpoints from '../../../constants/breakpoints';
import * as InternalPropTypes from '../../../constants/internal-types';
import { colorEnum } from '../../../constants/colors';
import font from '../../../constants/fonts';
import TitleTypes from '../../../constants/title-types';
import SEOWeights from '../../../constants/cms/control-options/seo-weights';

const useStyles = {
  padding: '0',
  margin: '0',
  color: colorEnum.black,
  ...font.GNLight,
  '&.section': {
    ...font.GNBold,
    fontSize: '32px',
    lineHeight: '36px',
  },
  '&.next': {
    ...font.GNBold,
    fontSize: '40px',
  },
  '&.contact': {
    ...font.GNMedium,
    fontSize: '21px',
  },
  '&.cases': {
    ...font.GNBold,
    [Breakpoints.Mobile.mq]: {
      fontSize: '32px',
      lineHeight: '38px',
    },
    [Breakpoints.TabletUp.mq]: {
      fontSize: '48px',
      lineHeight: '58px',
    },
  },
  '&.teaser': {
    fontSize: '21px',
    lineHeight: '24px',
    fontWeight: '400',
  },
  '&.buzzword': {
    ...font.GNBook,
    fontSize: '21px',
    lineHeight: '32px',
  },
  '&.navigable': {
    ...font.GNBook,
    fontSize: '18px',
    lineHeight: '21px',
  },
  '&.lead': {
    ...font.GNBook,
    fontSize: '24px',
    fontWeight: '400',
    lineHeight: '28px',
  },
};

type TitleProps = {
  type?: TitleTypes;
  className?: string;
  children?: InternalPropTypes.StringOrRichTextOrElementOrNestedArrays;
  seoWeight?: React.ElementType;
};

class Title extends Component<TitleProps> {
  static defaultProps = {
    type: TitleTypes.SECTION,
    className: null,
    children: null,
    seoWeight: SEOWeights.H2,
  };

  render() {
    const { children, type, className, seoWeight } = this.props;

    const Elem = seoWeight;
    let output = children;
    if (InternalPropTypes.isRichText(children)) {
      // eslint-disable-next-line no-multi-assign
      output = (
        <RichTextWrapper
          richText={children as InternalPropTypes.RichText}
          renderFunction={RichTextRenderer.regularTextOnly}
        />
      );
    }
    return (
      <Elem
        css={useStyles}
        className={type + (className ? ` ${className}` : '')}
      >
        {output}
      </Elem>
    );
  }
}

export default Title;
